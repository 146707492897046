<template>
  <Item :item="item" :parent="parent" :class="{ 'inline-flex': isTwitter }">
    <div v-append="item.settings.html" />
  </Item>
</template>

<script>
import { get } from 'vuex-pathify'
import itemMixin from '@/mixins/item'

export default {
  name: 'EmbedItem',

  components: {
    Item: () => import('@/components/Item'),
  },

  mixins: [itemMixin],

  computed: {
    isAdminMode: get('isAdminMode'),

    isTwitter() {
      try {
        const url = new URL(this.item.settings.url)
        return url.hostname === 'twitter.com'
      } catch (e) {
        return false
      }
    },
  },
}
</script>
